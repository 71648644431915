import axios from 'axios';
let baseUrl = '/zhcwaterweb';
var qs = require('qs');  



//技术文档
export const getList = params=>{
    let pm=qs.stringify(params);
    return axios.post(`${baseUrl}/mweb/document/getList`,pm);
}
//软件下载
export const getList1 = params=>{
    let pm=qs.stringify(params);
    return axios.post(`${baseUrl}/mweb/toolsoft/getList`,pm);
}